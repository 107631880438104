import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {DropDown} from '@ui';
import {formatPrice} from '@utils';
import {Logger} from '@helpers';
import {
  LOT_SURCHARGES_TYPE_SERVICE_FEE,
  LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_REGULAR,
  LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_SUV,
  LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_BUS,
  LOT_SURCHARGES_TYPE_AUTOBID_FEE_NET,
  LOT_SURCHARGES_TYPE_AUTOBID_FEE_GROSS,
  LOT_SURCHARGES_TYPE_AUTOBID_NET_SERVICE,
  LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_GROSS,
  LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_NET,
  LOT_SURCHARGES_TYPE_AUTOBID_DOCUMENTS_HANDLING,
  LOT_SURCHARGES_TYPE_AUTOBID_VAT_EU,
} from '@models';

import './FinalPrice.scss';

function FinalPrice() {
  const [t] = useTranslation('pages/lot');
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const bidAllowed = useSelector(state => state.user.bidAllowed);
  const lot = useSelector(state => state.lot);

  function formatType(type) {
    switch (type) {
      case LOT_SURCHARGES_TYPE_SERVICE_FEE:
        return t('commission_underlot');
      case LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_REGULAR:
        return t('car_delivery');
      case LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_SUV:
        return t('delivery_SUV');
      case LOT_SURCHARGES_TYPE_DELIVERY_LOCAL_BUS:
        return t('delivery_bus');
      case LOT_SURCHARGES_TYPE_AUTOBID_FEE_NET:
      case LOT_SURCHARGES_TYPE_AUTOBID_FEE_GROSS:
        return t('commission_AutoBid');
      case LOT_SURCHARGES_TYPE_AUTOBID_NET_SERVICE:
        return t('service_charge');
      case LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_GROSS:
      case LOT_SURCHARGES_TYPE_AUTOBID_ONLINE_HANDLING_NET:
        return t('online_processing');
      case LOT_SURCHARGES_TYPE_AUTOBID_DOCUMENTS_HANDLING:
        return t('processing_documents');
      case LOT_SURCHARGES_TYPE_AUTOBID_VAT_EU:
        return t('VAT');
      default:
        Logger.error('Some new SURCHARGES: ' + type);
    }
  }
  if (isAuthenticated && bidAllowed)
    return (
      <div className='FinalPrice'>
        <DropDown
          title={
            <div className='title'>
              <p className='heading'>
                {t('final_price')} {formatPrice(lot.prices.totalPrice)}
              </p>
              <p className='note'>{t('note')}</p>
            </div>
          }>
          <>
            {lot.prices.surcharges.map((item, key) => (
              <div key={key} className='surcharge'>
                <div className='title'>{formatType(item.taxType)}</div>
                <div className='price'>{formatPrice(item.value)}</div>
              </div>
            ))}
          </>
        </DropDown>
      </div>
    );
  return <></>;
}
export default FinalPrice;
