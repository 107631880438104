import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {formatPrice} from '@utils';
import VAT from '@assets/icons/VAT.png';

import {
  LOT_STATUS_FINISHED_NOT_SOLD,
  LOT_STATUS_FINISHED_RESERVED,
  LOT_STATUS_FINISHED_SOLD,
} from '@models';

import './CurrentBid.scss';

function CurrentBid() {
  const [t] = useTranslation('pages/lot');
  const lot = useSelector(state => state.lot);

  let title = t('highest_bit');
  if (!lot.prices.currentPrice) {
    return (
      <div className='CurrentBid ' style={{justifyContent: 'center'}}>
        <div className='title'>{t('no_bids_yet')}</div>
      </div>
    );
  }
  switch (lot.lotStatus) {
    case LOT_STATUS_FINISHED_SOLD:
    case LOT_STATUS_FINISHED_RESERVED:
      title = t('sold_for');
      break;
    case LOT_STATUS_FINISHED_NOT_SOLD:
      title = t('last_bit');
      break;
    default:
      //Do nothing
      break;
  }
  const {ownerPrice, currentPrice} = lot.prices;
  return (

    <div className='CurrentBid' style={{justifyContent: 'space-between'}}>
      <div className='title'>{title}</div>
      <div className={`price ${currentPrice > ownerPrice ? 'green' : 'red'}`}>
        {formatPrice(lot.prices.currentPrice)}
        {lot.prices.priceType === 2 ? (
          <div className='vat'>
            <img src={VAT} title={t('VAT')} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default CurrentBid;
