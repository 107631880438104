import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {Loader} from '@ui';
import {TopViews} from '@layers';
import {getLotInfo} from '@actions/lot';
import {useDispatchAsync} from '@hooks';

import LotGalleria from './lot-galleria/LotGalleria';
import Header from './header/Header';
import Description from './description/Description';
import AuctionBloc from './auction-bloc/AuctionBloc';
import LastBids from './last-bids/LastBids';

import './LotPage.scss';

function LotPage() {
  const params = useParams();
  const [t, i18n] = useTranslation('pages/lot');

  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatchAsync();
  const lot = useSelector(state => state.lot);

  useEffect(async () => {
    await setLoading(() => true);
    await dispatch(getLotInfo(params.id));
    await setLoading(() => false);
  }, [params.id]);

  useEffect(async () => {
    await dispatch(getLotInfo(params.id));
  }, [i18n.currentLang]);

  return (
    <div className='LotPage container'>
      {JSON.stringify(lot) === '{}' || isLoading === true ? (
        <Loader msg={''} />
      ) : (
        <>
          <Header />
          <div className='main'>
            <LotGalleria />
            <LastBids />
            <AuctionBloc />
          </div>
          <Description />
          <TopViews />
        </>
      )}
    </div>
  );
}
export default LotPage;
