import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';

import {AddToFavoriteBtn, Button} from '@ui';
import {ConvertedDate, Note} from '@components';
import {Logger} from '@helpers';
import {useDispatchAsync} from '@hooks';
import {getNextLotId, getPrevLotId} from '@actions/lot';

import {
  LOT_STATUS_NEW,
  LOT_STATUS_READY,
  LOT_STATUS_PREPARATION,
  LOT_STATUS_FINISHED_SOLD,
  LOT_STATUS_FINISHED_NOT_SOLD,
  LOT_STATUS_FINISHED_RESERVED,
  LOT_STATUS_HIDDEN,
  LOT_STATUS_ACTIVE,
  AUCTION_STATUS_LIVE,
  AUCTION_STATUS_FUTURE,
  AUCTION_STATUS_FINISHED,
} from '@models';

import './Header.scss';

function Header() {
  const params = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation('pages/lot');
  const dispatch = useDispatchAsync();
  const lot = useSelector(state => state.lot);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  function determineLotStatus() {
    switch (lot.lotStatus) {
      case LOT_STATUS_NEW:
        return t('newly_created');
      case LOT_STATUS_READY:
        return t('waiting_for_bids');
      case LOT_STATUS_PREPARATION:
        return t('waiting_for_auction_start');
      case LOT_STATUS_ACTIVE:
        return t('now_trading');
      case LOT_STATUS_FINISHED_SOLD:
        return t('sold_out');
      case LOT_STATUS_FINISHED_NOT_SOLD:
        return t('not_sold');
      case LOT_STATUS_FINISHED_RESERVED:
        return t('sold_with_reservation');
      case LOT_STATUS_HIDDEN:
        return t('unknown');
      default:
        Logger.error('Unknown lot status: ' + lot.lotStatus);
    }
  }
  function determineAuctionStatus() {
    switch (lot.auctionStatus) {
      case AUCTION_STATUS_LIVE:
        return t('active');
      case AUCTION_STATUS_FUTURE:
        return t('starting_soon');
      case AUCTION_STATUS_FINISHED:
        return t('completed');
      default:
        Logger.error('Unknown auction status: ' + lot.auctionStatus);
    }
  }

  async function goToNextLot() {
    const nextLotId = await dispatch(getNextLotId(params.id));
    if (nextLotId && nextLotId.status === 'ok') {
      navigate(`/lot/${nextLotId.id}`);
    }
  }

  async function goToPrevLot() {
    const prevLotId = await dispatch(getPrevLotId(params.id));
    if (prevLotId && prevLotId.status === 'ok') {
      navigate(`/lot/${prevLotId.id}`);
    }
  }

  if (!lot.prices) return <></>;
  return (
    <div className='Header'>
      <span className='name_bloc'>
        <div className='rightBloc'>
          {isAuthenticated ? (
            <>
              <AddToFavoriteBtn
                lotId={lot.lotId}
                onClick={e => e.stopPropagation()}
              />
              <Note lot={lot} />
            </>
          ) : null}
          <div className='name'>{lot.name}</div>
        </div>

        <div className='controlPanel'>
          {lot.auctionStatus === AUCTION_STATUS_LIVE &&
          lot.lotStatus !== LOT_STATUS_ACTIVE ? (
              <Button
                className={'gray'}
                onClick={() => {
                  navigate(`/lot/${lot.currentLotId}`);
                }}>
                {t('active_lot')} : {'  '}
                <div className='value'>{lot.currentLotIndex}</div>
              </Button>
            ) : null}
          <Button className={'gray'} onClick={goToPrevLot}>
            <i className='pi pi-angle-left' />
          </Button>
          <div className='bloc'>
            {t('number_queue')} :
            <div className='value'>
              {lot.index}/{lot.lotCount}
            </div>
          </div>
          <Button className={'gray'} onClick={goToNextLot}>
            <i className='pi pi-angle-right' />
          </Button>
        </div>
      </span>

      <div className='info'>
        <div className='bloc'>
          {t('id_car')} : <div className='value'>{lot.lotId}</div>
        </div>
        {lot.VIN ? (
          <div className='bloc'>
            VIN : <div className='value'>{lot.VIN}</div>
          </div>
        ) : null}

        <div className='bloc'>
          {t('start_Date')} :{' '}
          <div className='value'>
            <ConvertedDate date={lot.auctionStartsAt} />
          </div>
        </div>

        <div className='bloc'>
          {t('auto_status')} :
          <div className='value'>{determineLotStatus()}</div>
        </div>

        <div className='bloc'>
          {t('auction_status')} :
          <div className='value'>{determineAuctionStatus()}</div>
        </div>

        <div className='bloc'>
          {t('country')} :
          <div className='value'>
            {lot.country}{' '}
            <ReactCountryFlag
              countryCode={lot.country}
              style={{
                width: '1.5em',
                height: '1.5em',
              }}
              svg
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
