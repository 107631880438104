import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {InputNumber, Button, Input} from '@ui';
import {Toast} from '@helpers';
import {REGEX_NOT_SPACES} from '@helpers/regex';
import {useUpdatedState, useValidation, useDispatchAsync} from '@hooks';
import {feedbackRequest} from '@actions/user';
import pp_paynow from '@assets/images/pp_paynow.png';

import './Footer.scss';

function Footer() {
  const [th] = useTranslation('pages/help');
  const [t] = useTranslation('', {keyPrefix: 'Footer'});

  const dispatch = useDispatchAsync();
  const isInputsValid = useValidation('Footer_input', 'color');
  const userInfo = useSelector(state => state.user.userInfo);
  const userId = useSelector(state => state.user.id);

  const [first_name, setFirstName] = useUpdatedState(
    userInfo && userInfo.first_name ? userInfo.first_name : null
  );

  const phone_state = useSelector(state => state.user.phone);
  const [phone, setPhone] = useUpdatedState(phone_state || null);

  async function onSentHandler() {
    if (!isInputsValid) {
      Toast.warning(th('message.invalid_inputs'), 5000);
      return;
    }

    const res = await dispatch(feedbackRequest(userId, first_name, phone));
    if (res.ok) {
      Toast.success(th('message.ok'), 5000);
    }
  }

  return (
    <div className='Footer'>
      <div className='container'>
        <div className='footer-container'>
          <div className='footer-left'>
            <div className='footer_logo'>
              <div className='footer_logo_text'>UnderLot</div>
            </div>
            <div className='footer_address'>
              <div className='footer_address_line'>support@underlot.com</div>
              <div className='footer_address_line'>
                +48 662 533 889
                <br />
                +380 67 765 2858
              </div>
            </div>
            <div className='footer_social'>
              <Link to='#'>Instagram</Link>
              <Link to='#'>Facebook</Link>
            </div>
            <div className='pp_paynow'>
              <img src={pp_paynow} alt='pp_paynow' />
            </div>
          </div>

          <div className='footer-right'>
            <div className='footer_title'>
              <div className='title'>
                {t('contacts.title')}
              </div>
              <div className='desc'>{t('contacts.text')}</div>
            </div>

            <div className='footer_form'>
              <Input
                className='Footer_input'
                type='text'
                pattern={REGEX_NOT_SPACES}
                value={first_name}
                headerTitle={th('inputs.name.headerTitle')}
                placeholder={th('inputs.name.placeholder')}
                underTitle={th('inputs.name.underTitle')}
                onChange={e => setFirstName(e.target.value)}
              />
              <InputNumber
                className='Footer_input'
                phone={phone}
                title={th('inputs.phone.headerTitle')}
                placeholder={th('inputs.phone.placeholder')}
                underTitle={th('inputs.phone.underTitle')}
                pattern={REGEX_NOT_SPACES}
                onChangeHandler={setPhone}
              />
              <Button onClick={onSentHandler} text={th('btSent')} />
            </div>

            <div className='footer_links'>
              <Link to='/contacts'>{t('about')}</Link>
              <Link to='/general-terms-of-use'>{t('rules')}</Link>
              <Link to='/privacy-policy'>{t('privacy')}</Link>
              <Link to='/info-about-auction'>{t('faq')}</Link>
              <Link to='/consideration-claims'>{t('claims')}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
