import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {CheckBox, Button} from '@ui';
import {ShadowContainer} from '@containers';
import {CardLotProcessing} from '@components';

import {ReactComponent as WireTransfer} from '@assets/icons/wire-transfer.svg';
//import imoje from '@assets/images/imoje.jpg';
import pp_paynow from '@assets/images/pp_paynow.png';


import {
  LOT_PROCESSING_STATUS_ADMIN_CHECKED,
  ORDER_PAYMENT_PROVIDER_ING_PL,
  ORDER_PAYMENT_PROVIDER_PAYNOW,
  ORDER_PAYMENT_PROVIDER_MANUAL,
  ORDER_PRODUCT_TYPE_LOT,
  ORDER_PRODUCT_TYPE_TARIFF,
} from '@models';

import './ChoosingPaymentMethod.scss';

function ChoosingPaymentMethod({
  isLoading,
  orderType,
  tariff,
  lot,
  paymentMethod,
  setPaymentMethod,
  onNext,
}) {
  const [isCanPay, setIsCanPay] = useState(true);
  const [t] = useTranslation('pages/payments');

  return (
    <div className='ChoosingPaymentMethod container'>
      <div className='info'>
        {(() => {
          switch (orderType) {
            case ORDER_PRODUCT_TYPE_TARIFF:
              return (
                <ShadowContainer className='tariffInfo'>
                  <p className='title'>{t('tariff_title')}</p>
                  <p>{t('tariff_options')} :</p>
                  <div className='info'>
                    <p>{tariff.header}</p>
                    <p>{tariff.limit}</p>
                    <p>{tariff.active_auctions}</p>
                  </div>
                </ShadowContainer>
              );
            case ORDER_PRODUCT_TYPE_LOT:
              if (!lot && isLoading === false) {
                isCanPay && setIsCanPay(false);
                return <div>{t('error_load_lot_info')}</div>;
              }
              if (
                lot.lotProcessing &&
                lot.lotProcessing.status ===
                  LOT_PROCESSING_STATUS_ADMIN_CHECKED &&
                lot.lotProcessing.invoice.no
              ) {
                return <CardLotProcessing lot={lot} isShowPayBtn={false} />;
              } else {
                isCanPay && setIsCanPay(false);
                return <div>{t('un_correct_lot_info')}</div>;
              }
            default:
              return <></>;
          }
        })()}
      </div>
      {isCanPay ? (
        <>
          <div className='paymentMethods'>
            <div>
              <ShadowContainer
                onClick={() => setPaymentMethod(ORDER_PAYMENT_PROVIDER_MANUAL)}>
                <h4>{t('wire_transfer')}</h4>
                <WireTransfer className='WireTransfer'/>
              </ShadowContainer>
              <CheckBox
                state={paymentMethod === ORDER_PAYMENT_PROVIDER_MANUAL}
                setState={() => setPaymentMethod(ORDER_PAYMENT_PROVIDER_MANUAL)}
              />
            </div>

            {/*
            <div>
              <ShadowContainer
                onClick={() => setPaymentMethod(ORDER_PAYMENT_PROVIDER_ING_PL)}>
                <h4>{t('payment_by_card')}</h4>
                <div>ING Bank Śląski</div>
                <div className='imoje'>
                  <img src={imoje} alt='imoje'/>
                </div>
              </ShadowContainer>
              <CheckBox
                state={paymentMethod === ORDER_PAYMENT_PROVIDER_ING_PL}
                setState={() => setPaymentMethod(ORDER_PAYMENT_PROVIDER_ING_PL)}
              />
            </div>
            */}

            <div>
              <ShadowContainer
                onClick={() => setPaymentMethod(ORDER_PAYMENT_PROVIDER_PAYNOW)}>
                <h4>{t('payment_by_card')}</h4>
                <div>Paynow</div>
                <div className='pp_paynow'>
                  <img src={pp_paynow} alt='Paynow'/>
                </div>
              </ShadowContainer>
              <CheckBox
                state={paymentMethod === ORDER_PAYMENT_PROVIDER_PAYNOW}
                setState={() => setPaymentMethod(ORDER_PAYMENT_PROVIDER_PAYNOW)}
              />
            </div>
          </div>
          <div className='note'>
            {paymentMethod === ORDER_PAYMENT_PROVIDER_MANUAL ? (
              <>
                <p>{t('note_manual_1')} </p>
                <p>{t('note_manual_2')}</p>
              </>
            ) : null}
            {paymentMethod === ORDER_PAYMENT_PROVIDER_ING_PL ? (
              <>
                <p>{t('note_ing_pl_1')} </p>
                <p>{t('note_ing_pl_2')}</p>
              </>
            ) : null}
            {paymentMethod === ORDER_PAYMENT_PROVIDER_PAYNOW ? (
              <>
                <p>{t('note_pp_paynow_1')} </p>
                <p>{t('note_ing_pl_2')}</p>
              </>
            ) : null}
          </div>
          <div className='btNext'>
            <Button
              className={paymentMethod === null ? 'inactive' : ''}
              onClick={onNext}>
              {t('continue')}
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ChoosingPaymentMethod;
