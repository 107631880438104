import {createStore, compose, applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';

// reducers
import passwordRecoveryReducer from './password-recovery/reducer';
import registrationReducer from './registration/reducer';
import auctionsReducer from './auction/reducer';
import myCarsReducer from './my-cars/reducer';
import adminReducer from './admin/reducer';
import agentReducer from './agent/reducer';
import userReducer from './user/reducer';
import homeReducer from './home/reducer';
import lotsReducer from './lots/reducer';
import lotReducer from './lot/reducer';
const logger = createLogger({collapsed: true});

const middlewares = ['production', 'staging'].includes(process.env.NODE_ENV)
  ? [thunk, logger]
  : [thunk, logger];
const composeEnhancers =
  (!['production', 'staging'].includes(process.env.NODE_ENV) &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const rootReducer = combineReducers({
  passwordRecovery: passwordRecoveryReducer,
  registration: registrationReducer,
  auctions: auctionsReducer,
  myCars: myCarsReducer,
  admin: adminReducer,
  agent: agentReducer,
  lots: lotsReducer,
  user: userReducer,
  home: homeReducer,
  lot: lotReducer,
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
